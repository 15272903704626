import styled from "styled-components";
export const PeritajeStyles = styled.section`
  color: #303030;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  h1 {
    font-size: 3rem;
    margin: 0 0 1.8rem;
    position: relative;
    line-height: 1.2;
    font-weight: 700;
    color: #303030;
    color: var(--color__primary-dark);
  }
    
  h2 {  // Reference code for the letters please visit gomuxmonux to see example
    font-size: 2.3rem;
    margin: 0 0 1.5rem;
    position: relative;
    line-height: 1.2;
    font-weight: 600;
    color: #303030;
    color: var(--color__secondary-dark);
  }

  h3 {
      font-size: 1.8rem;
      margin: 0 0 1.2rem;
      position: relative;
      line-height: 1.3;
      font-weight: 600;
      color: #303030;
      color: var(--color__tertiary-dark);
  }

  
  b, strong {
      font-weight: bold;
      color: #303030;
      color: var(--color__dark);
  }
  
  i, em {
      font-style: italic;
      color: #303030;
      color: var(--color__dark);
  }


  p {
    font-size: 1.2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 1.5;
    color: #303030;
    color: var(--color__dark);
  }

  button {
    /* background-color: #e2b33c; */
    align-self: center;
    @media (max-width: 1127px) {
      margin: 0 auto;
    }
  }

  a {
    display: block;
    align-self: center;
    @media (max-width: 1127px) {
      margin: 0 auto;
    }
  }
  > div {
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
  }
  
  ul {
    font-size: 1.2rem;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    list-style-type: none; /* Remove default bullet points */

    li {
      display: flex;
      gap: 0.375rem;
      margin: 0.375rem 0 0.375rem 1.5rem;
      padding-left: 1.5rem;
      line-height: 1.5rem;

      flex-direction: column;
      @media (min-width: 1128px) {
        flex-direction: row;
      }
      @media (max-width: 1127px) {
        text-align: center;
        padding-left: 0.75rem
      }
    }
  }
`;

export const ImageContainer = styled.div`
  height: 200px;
  width: 200px;
  border-radius: 3%;
  flex-shrink: 0;
  align-self: center;
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-position: center;
`;

export const Info = styled.div`
  padding: 36px;
  background-color: #fce2cf;    //Color of posts
  border-radius: 4px;
`;

export const ServiceContainer = styled.div`
  flex-grow: 1;
`;
